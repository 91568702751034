import { Button, Col, Form, FormInstance, Modal, Row, Spin } from 'antd'
import { AxiosError } from 'axios'
import { get, uniqueId } from 'lodash'
import React from 'react'
import DeviceApi from '../../Api/DeviceApi'
import SimApi from '../../Api/SimApi'
import SelectMyAccount from '../../Component/SelectMyAccount'
import SecurityService from '../../Util/SecurityService'
import { PERMISSIONS } from '../../Util/Constants'
import AccountDomain from '../../Domain/AccountDomain'
import { NotificationCommon } from '../../Component/Notification'

interface Props {
    onVisibleChange: (visible: boolean) => void
    onConfigSuccess: () => void
    deviceCode: string
    accounts: Array<AccountDomain>
}

interface State {
    loading: boolean
    sims: Array<any>
    simConfigs: Array<SimConfigsInterface>
}

interface SimConfigsInterface {
    sim?: string
    configs?: Array<SimConfigInterface>
}

interface SimConfigInterface {
    id: string
    sim: string
    account: string
    sender: string
    _action: string
}

class SenderConfigModal extends React.Component<Props, State> {
    formRef = React.createRef<FormInstance>()

    state: State = {
        loading: false,
        sims: [],
        simConfigs: [],
    }

    async componentDidMount() {
        await this.fetchSims()
    }

    fetchSims = async () => {
        try {
            this.setState({ loading: true })
            const response = await DeviceApi.getSimsByDeviceCode(this.props.deviceCode)
            this.setState({ sims: response.data })

            const simConfigs: Array<SimConfigsInterface> = []

            for (let sim of response.data) {
                const configResponse = await SimApi.getConfig(sim.code)
                simConfigs.push({
                    sim: sim.code,
                    configs: configResponse.data.map((conf: any) => ({
                        ...conf,
                        _action: 'update',
                    })),
                })

                const accounts: any = {}
                const senders: any = {}
                for (let config of configResponse.data) {
                    accounts[config.id] = config.account
                    senders[config.id] = config.sender
                }

                this.formRef.current?.setFieldsValue({
                    [sim.code]: {
                        account: accounts,
                        sender: senders,
                    },
                })
            }

            this.setState({
                loading: false,
                simConfigs,
            })
        } catch (error: any) { }
    }

    handleCancel = () => {
        this.props.onVisibleChange(false)
    }

    handleAddConfigRow = (simCode: string) => {
        const { simConfigs } = this.state
        const config: any = simConfigs.find((conf: any) => conf.sim === simCode)
        if (!config.configs) config.configs = []
        config.configs.push({
            id: uniqueId('config_id_'),
            _action: 'add',
            account: '',
            sender: '',
            sim: simCode,
        })
        this.setState({ simConfigs })
    }

    handleRemoveConfig = (config: any) => {
        if (config._action === 'add') {
            const { simConfigs } = this.state
            const configs = this.getSimConfigRows(config.sim)
            const simConfig: any = this.getSimConfigItem(config.sim)
            simConfig.configs = configs.filter((conf: any) => conf.id !== config.id)

            this.setState({
                simConfigs: simConfigs.map((conf) => {
                    if (conf.sim === simConfig.sim) {
                        conf = { ...simConfig }
                    }
                    return conf
                }),
            })
        }
    }

    handleAddConfig = (config: SimConfigInterface) => {
        const sender = this.formRef.current?.getFieldValue([config.sim, 'sender', config.id])
        const account = this.formRef.current?.getFieldValue([config.sim, 'account', config.id])
        this.setState({ loading: true })
        SimApi.addConfig(config.sim, { sender, account })
            .then(() => {
                this.handleApiUpdateSuccess()
                this.fetchSims()
            })
            .catch(this.handleApiUpdateFail)
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleUpdateConfig = (config: SimConfigInterface) => {
        const sender = this.formRef.current?.getFieldValue([config.sim, 'sender', config.id])
        const account = this.formRef.current?.getFieldValue([config.sim, 'account', config.id])
        this.setState({ loading: true })
        SimApi.updateConfig(config.sim, config.id, { sender, account })
            .then(this.handleApiUpdateSuccess)
            .catch(this.handleApiUpdateFail)
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    getSimConfigRows = (simCode: string) => {
        const { simConfigs } = this.state
        return (
            get(
                simConfigs.find((conf: any) => conf.sim === simCode),
                'configs',
                []
            ) || []
        )
    }

    getSimConfigItem = (simCode: string) => {
        const { simConfigs } = this.state
        return simConfigs.find((conf: any) => conf.sim === simCode)
    }

    handleApiUpdateSuccess = () => {
        NotificationCommon.success({
            message: 'Cập nhật cấu hình thành công',
        })
    }

    handleApiUpdateFail = (error: AxiosError) => {
        if (get(error.response, 'status') === 400) {
            if (get(error.response, 'data.title') === 'type_account_wrong') {
                NotificationCommon.error({
                    message: 'Cấu hình chỉ hỗ trợ tài khoản ngân hàng',
                })
            }
            if (get(error.response, 'data.title') === 'sim_config_existed') {
                NotificationCommon.error({
                    message: 'Cấu hình đã tồn tại',
                })
            }
            if (get(error.response, 'data.title') === 'sim_has_been_connected_to_a_sender') {
                // const simValue = get(error.response, 'data.detail').match(/sim\s'([^']+)'/)[1];
                const senderValue = get(error.response, 'data.detail').match(/sender\s'([^']+)'/)[1];

                NotificationCommon.error({
                    message: `Thiết bị đã kết nối đến tài khoản quỹ khác cùng ngân hàng ${senderValue}. Vui lòng kiểm tra lại`,
                })
            }
            else {
                NotificationCommon.error({
                    message: 'Cập nhật cấu hình thất bại',
                })
            }
        } else {
            NotificationCommon.error({
                message: 'Cập nhật cấu hình thất bại. Lỗi: ' + error.message,
            })
        }
    }

    render() {
        const { loading, sims } = this.state
        const { accounts } = this.props

        return (
            <Modal
                visible={true}
                title={'Cấu hình sender'}
                closeIcon={<i className="fa-solid fa-xmark" />}
                footer={false}
                onCancel={this.handleCancel}
                width={893}
                okButtonProps={{
                    style: { display: 'none' },
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading,
                }}
                centered
                bodyStyle={{
                    paddingTop: 0,
                }}
            >
                <Form ref={this.formRef} className="bottom-item-8">
                    <Spin spinning={loading}>
                        {sims.map((sim, index) => (
                            <div key={index} className={'mg-bt-8 pd-t-8'} style={{ borderTop: '1px solid #f0f0f0' }}>
                                {this.getSimConfigRows(sim.code).map((conf: SimConfigInterface, confIndex: number) => (
                                    <Row>
                                        <div className="flex1">
                                            <Row key={confIndex} gutter={12}>
                                                {/*<Col xs={24} md={12}>*/}
                                                {/*    <Form.Item*/}
                                                {/*        name={[sim.code, 'sender', conf.id]}*/}
                                                {/*        label={'Sender'}*/}
                                                {/*        rules={[{ required: true, message: 'Sender không được để trống' }]}*/}
                                                {/*    >*/}
                                                {/*        <Input autoFocus={true} placeholder="Vui lòng nhập sender" />*/}
                                                {/*    </Form.Item>*/}
                                                {/*</Col>*/}
                                                <Col xs={24} md={24}>
                                                    <Form.Item
                                                        name={[sim.code, 'account', conf.id]}
                                                        label={'Tài khoản'}
                                                        rules={[
                                                            { required: true, message: 'Vui lòng chọn tài khoản quỹ' },
                                                        ]}
                                                    >
                                                        <SelectMyAccount initialItems={accounts} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Form.Item className="buttons-config" shouldUpdate>
                                            {() => {
                                                // const sender = this.formRef?.current?.getFieldValue([
                                                //     sim.code,
                                                //     'sender',
                                                //     conf.id,
                                                // ])
                                                const account = this.formRef?.current?.getFieldValue([
                                                    sim.code,
                                                    'account',
                                                    conf.id,
                                                ])

                                                return (
                                                    <div className={'flex'}>
                                                        {conf._action === 'update'
                                                            ? SecurityService.can(PERMISSIONS.SIM_CONFIG_UPDATE) && (
                                                                <Button
                                                                    style={{ width: '100%' }}
                                                                    type="primary"
                                                                    disabled={!account}
                                                                    onClick={() => {
                                                                        if (
                                                                            SecurityService.can(
                                                                                PERMISSIONS.SIM_CONFIG_UPDATE
                                                                            )
                                                                        ) {
                                                                            this.handleUpdateConfig(conf)
                                                                        }
                                                                    }}
                                                                >
                                                                    Cập nhật
                                                                </Button>
                                                            )
                                                            : SecurityService.can(PERMISSIONS.SIM_CONFIG_CREATE) && (
                                                                <Button
                                                                    type="primary"
                                                                    disabled={!account}
                                                                    onClick={() => {
                                                                        if (
                                                                            SecurityService.can(
                                                                                PERMISSIONS.SIM_CONFIG_CREATE
                                                                            )
                                                                        ) {
                                                                            this.handleAddConfig(conf)
                                                                        }
                                                                    }}
                                                                >
                                                                    Cập nhật
                                                                </Button>
                                                            )}

                                                        {conf._action === 'add' &&
                                                            SecurityService.can(PERMISSIONS.SIM_CONFIG_UPDATE) && (
                                                                <Button
                                                                    danger
                                                                    onClick={this.handleRemoveConfig.bind(this, conf)}
                                                                    className="mg-l-8"
                                                                >
                                                                    Xoá
                                                                </Button>
                                                            )}
                                                    </div>
                                                )
                                            }}
                                        </Form.Item>
                                    </Row>
                                ))}

                                {SecurityService.can(PERMISSIONS.SIM_CONFIG_CREATE) && (
                                    <Button
                                        type={'link'}
                                        onClick={this.handleAddConfigRow.bind(this, sim.code)}
                                        icon={<i className="fa-solid fa-plus pd-r-4 pd-bt-2" />}
                                        className="pd-l-4 text-blue"
                                    >
                                        Thêm sender
                                    </Button>
                                )}
                            </div>
                        ))}
                    </Spin>
                </Form>
            </Modal>
        )
    }
}

export default SenderConfigModal
