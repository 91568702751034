import { AxiosResponse } from 'axios'
import apiClient from '../Util/ApiClient'
import AccountDomain from '../Domain/AccountDomain'

export default class ProjectApi {
    static filter = (query = {}, includeInactiveProject = false) => {
        return apiClient
            .get('/projects', {
                params: query,
            })
            .then((res: AxiosResponse<AccountDomain[]>) => {
                if (includeInactiveProject) {
                    return res
                }
                const newRes = { ...res }
                newRes.data = res.data.filter((account) => account.status !== 'INACTIVE')
                // console.log('newRes', newRes.data)
                return newRes as any
            })
    }
}
