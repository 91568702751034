import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Paragraph from "antd/lib/typography/Paragraph";
import lodash from 'lodash';
import moment from 'moment';
import { Component } from "react";
import { Link } from "react-router-dom";
import CommonTable from '../../../Component/CommonTable';
import ProjectDomain from "../../../Domain/ProjectDomain";
import ReceiptVoucherDomain, { ReceiptVoucherDetails } from "../../../Domain/ReceiptVoucherDomain";
import { ACCOUNT_PERMISSIONS, STATUSES_CODE } from "../../../Util/Constants";
import SecurityService from '../../../Util/SecurityService';
import Utils from "../../../Util/Utils";

interface Props {
    loading?: boolean,
    items: Array<ReceiptVoucherDomain>
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize?: number) => void
    projects: Array<ProjectDomain>
    cloneVoucher: (voucher: ReceiptVoucherDetails) => void
    memoTypes?: any[]
}

class DataList extends Component<Props, any> {
    getColumns = () => {
        const { memoTypes } = this.props
        const columns: ColumnsType<any> = [
            {
                title: "Mã phiếu/ Mã giao dịch",
                key: "transaction_code",
                render: (row: any) => {
                    const code = lodash.get(row, 'code');
                    const transaction = lodash.get(row, 'transaction');
                    return (
                        <div style={{ minWidth: 233 }}>
                            <div>
                                <span className="secondary2-color">Mã giao dịch:</span>{' '}
                                <Paragraph className={"italic transaction-code d-inline-block"} copyable={transaction && { icon: <i className="fa-regular fa-copy" style={{ color: '#7D7E7E', fontSize: 12 }} />, text: transaction }}>{transaction || '--'}</Paragraph>
                            </div>
                            <div>
                                <span className="secondary2-color">Mã phiếu:</span>{' '}
                                <Paragraph className={"text-blue d-inline-block"} copyable={code && { icon: <i className="fa-regular fa-copy" style={{ color: '#7D7E7E', fontSize: 12 }} />, text: code }}><Link to={`/receipt-vouchers/${code}`}>{code}</Link></Paragraph>
                            </div>
                            {
                                row.orderCode && <div>
                                    <span className="secondary2-color">Mã thanh toán:</span>{' '}
                                    <Paragraph className={"italic d-inline-block"} copyable={row.orderCode && { icon: <i className="fa-regular fa-copy" style={{ color: '#7D7E7E', fontSize: 12 }} />, text: row.orderCode }}>
                                        <span>{row.orderCode}</span>
                                    </Paragraph>
                                </div>
                            }
                            {
                                row.deliveryNote && <div>
                                    <span className="secondary2-color">Mã phiếu xuất:</span>{' '}
                                    <Paragraph className={"italic d-inline-block"} copyable={row.deliveryNote && { icon: <i className="fa-regular fa-copy" style={{ color: '#7D7E7E', fontSize: 12 }} />, text: row.deliveryNote }}>
                                        <span>{row.deliveryNote}</span>
                                    </Paragraph>
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: "Thời gian giao dịch",
                key: "timestamp",
                render: (row: any) => {
                    const timestamp = row.timestamp ? moment(row.timestamp).format("DD/MM/YYYY HH:mm:ss") : "--";
                    return (
                        <div style={{ width: 133 }}>
                            {timestamp}
                        </div>
                    )
                }
            },
            {
                title: "Loại",
                key: "type",
                width: '10%',
                render: (row) => {
                    return <div>
                        {/* <span>{TRANSACTION_TYPES[row.type]}</span> */}
                        {memoTypes?.find((m: any) => m.code === row.type)?.name || row.type}
                    </div>
                },
            },
            {
                title: "Hệ thống",
                key: "project",
                width: '10%',
                dataIndex: 'project',
            },
            {
                title: "Người nộp tiền",
                key: "payer",
                dataIndex: "payer",
                render: (payer: string, row: any) => {
                    const { projects } = this.props;
                    if (row.payerRef) {
                        const cusUrl = Utils.getCustomerProjectUrl(projects, row.payerRef.project, row.payerRef.username);
                        return (
                            <Tooltip title={row.payer} placement={'top'}>
                                {row.payerRef
                                    ? (<a href={cusUrl} target={'_blank'} rel={'noreferrer'}>
                                        {lodash.get(row.payerRef, 'username')}</a>)
                                    : lodash.get(row.payerRef, 'username')}
                            </Tooltip>
                        )
                    }
                    return payer;
                }
            },
            {
                title: "Nội dung giao dịch",
                key: "memo",
                dataIndex: "memo"
            },
            {
                title: "Số tiền",
                key: "amount",
                dataIndex: "amount",
                align: 'right',
                render: (amount: any, row: any) => {
                    const currency = lodash.get(row, 'currency.code');
                    return <span className={`bold fsz-16px positive-money`}>
                        {Utils.currencyFormatByCurrency(amount, currency)}
                    </span>
                }
            },
            {
                title: "Trạng thái",
                key: "status",
                dataIndex: "status",
                align: 'right',
                render: (s: any) => {
                    let status = STATUSES_CODE.find((x) => x.code === s.code)
                    return <span className={`status-${status?.code}`}>{status?.name}</span>
                }
            },
            {
                title: "Trạng thái phiếu",
                key: "reviewStatus",
                dataIndex: "reviewStatus",
                align: 'center',
                render: (reviewStatus: string) => {
                    if (reviewStatus)
                        return (<Tooltip title={reviewStatus === 'PENDING' ? 'Chờ Duyệt' : 'Đã Duyệt'}>
                            <span className={'font-size-16'}
                                style={{ color: reviewStatus === 'PENDING' ? '#B1B1B1' : '#4DD69C' }}>
                                <i className="fa-solid fa-circle-check" />
                            </span>
                        </Tooltip>)
                }
            },

        ]

        if (SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_CREATE)) {
            columns.push({
                title: '',
                key: 'actions',
                width: 32,
                align: 'center',
                fixed: 'right',
                render: (row: any) => {
                    return (
                        <div className="actions" onClick={(e) => e.stopPropagation()}>
                            <Tooltip title="Nhân bản phiếu" placement="left">
                                <Button
                                    onClick={() => this.props.cloneVoucher(row)}
                                    type="primary"
                                    className="icon-btn"
                                >
                                    <i className="fa-solid fa-clone" />
                                </Button>
                            </Tooltip>
                        </div>
                    )
                },
            })
        }
        return columns
    }

    handleChangePage = (page: number, pageSize?: number) => {
        this.props.onChangePage(page, pageSize)
    }

    render() {
        const { loading, total, pageSize, items, currentPage } = this.props;

        return (
            <>
                <CommonTable
                    className="large-height-row"
                    columns={this.getColumns()}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    emptyText="Danh sách phiếu thu đang trống"
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 25, 50, 100],
                        onChange: this.handleChangePage,
                        onShowSizeChange: this.handleChangePage
                    }}
                />
            </>
        );
    }
}

export default DataList;
