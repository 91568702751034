import apiClient from '../Util/ApiClient'
import MemoMappingCommand from '../Command/MemoMappingCommand'
import { MemoCriteria } from '../QueryCriteria/MemoCriteria'

export default class MemoApi {
    static config = (data: MemoMappingCommand) => {
        return apiClient.post(`/memo-mappings`, data)
    }

    static filter = (criteria: MemoCriteria) => {
        return apiClient.get('/memo-mappings', { params: criteria })
    }

    static delete = (memoMappingID: string) => {
        return apiClient.delete(`/memo-mappings/${memoMappingID}`)
    }

    static types = () => {
        return apiClient.get(`/memo-mapping-types`)
    }
}
