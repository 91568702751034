import { Checkbox, Form, Input, Modal } from 'antd';
import { AxiosError } from "axios";
import { omitBy } from 'lodash';
import React from 'react';
import DeviceApi from "../../Api/DeviceApi";
import DeviceDomain, { DeviceModel } from "../../Domain/DeviceDomain";
import { css } from '@emotion/css';

interface State {
    form: DeviceDomain,
    loading: boolean
}

interface Props {
    onCreateSuccess: (device: DeviceDomain) => void,
    onCreateFail: (error: AxiosError) => void,
    onVisibleChange: (visible: boolean) => void
}

class DeviceCreate extends React.Component<Props, State> {

    state = {
        form: { ...DeviceModel },
        loading: false
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value
            }
        })
    };

    handleChangeCheckbox = (checked: boolean) => {
        this.setState({
            form: {
                ...this.state.form,
                connectOneSender: checked
            }
        })
    }

    handleSubmit = () => {
        const data: DeviceDomain = omitBy(this.state.form, (value: any) => (value === "" || value === undefined || value === null));
        if (data.connectOneSender) this.handleWarningWhenConnectOneSenderChecked(data)
        else this.handleCreateDevice(data)
    }

    handleWarningWhenConnectOneSenderChecked = (data: DeviceDomain) => {
        Modal.confirm({
            centered: true,
            title: '',
            content: <div className='flex gap-2'>
                <i className="fa-sharp fa-regular fa-circle-exclamation text-base text-orange-500"></i>
                <p className='text-sm'>Lưu ý: Với mỗi ngân hàng, thiết bị chỉ được dùng duy nhất 1 số tài khoản!</p>
            </div>,
            okText: 'Đồng Ý',
            okButtonProps: {
                loading: this.state.loading,
            },
            onOk: () => this.handleCreateDevice(data),
            cancelText: "Huỷ",
            cancelButtonProps: {
                disabled: this.state.loading,
            }
        })
    }

    handleCreateDevice = (data: DeviceDomain) => {
        this.setState({ loading: true });
        DeviceApi.create(data)
            .then((response) => {
                this.props.onCreateSuccess(response.data);
            })
            .catch((error: AxiosError) => {
                this.props.onCreateFail(error);
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    render() {
        const { form, loading } = this.state;
        return (
            <Modal
                visible={true}
                title={'Tạo thiết bị'}
                okText={"Xác Nhận"}
                closeIcon={<i className="fa-solid fa-xmark" />}
                cancelText={"Hủy Bỏ"}
                width={660}
                onOk={this.handleSubmit}
                onCancel={() => this.props.onVisibleChange(false)}
                okButtonProps={{
                    loading: loading,
                    disabled: loading || !form.name,
                    tabIndex: 2,
                    icon: <i className="fa-solid fa-check pd-r-8" />
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading,
                    tabIndex: 3,
                    icon: <i className="fa-solid fa-xmark pd-r-8" />,
                    type: 'ghost'
                }}
                centered
                className={css({
                    '.ant-modal-header': {
                        borderBottom: `0 solid`
                    },
                    '.ant-modal-body': {
                        padding: `0 24px !important`
                    },
                    '.ant-modal-footer': {
                        borderTop: `0 solid`
                    },
                })}
            >
                <Form>
                    <Form.Item
                        label={"Tên thiết bị"}
                        className=""
                        name="name"
                        rules={[{ required: true, message: 'Tên thiết bị không được để trống' }]}
                    >
                        <Input
                            autoFocus={true}
                            tabIndex={1}
                            className={'width-100pc'}
                            placeholder="Vui lòng nhập tên thiết bị"
                            value={form.name}
                            disabled={loading}
                            onChange={this.handleChangeInput.bind(this, 'name')}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>
                    <Form.Item valuePropName="checked" name={'connectOneSender'} className='mb-0'>
                        <Checkbox
                            onChange={(e) => {
                                this.handleChangeCheckbox(e.target.checked)
                            }}
                        >Nhận diện tài khoản ngân hàng theo thiết bị</Checkbox>
                    </Form.Item>
                </Form>
            </Modal >
        )
    }
}

export default DeviceCreate;
