import { useEffect } from "react";
import { useRecoilState } from "recoil";
import MemoApi from "../Api/MemoApi";
import { memoTypeState } from "../recoil";

interface Props { }

function withRecoilState<T>(WrappedComponent: React.ComponentType<T & Props>) {
  const EnhancedComponent: React.FC<T> = (props) => {
    const [memoTypes, setMemoTypes] = useRecoilState<any>(memoTypeState)

    useEffect(() => {
      const fetchMemoTypes = async () => {
        try {
          const rs = await MemoApi.types();
          setMemoTypes(rs.data)
        } catch (err) {
          ///
        }
      }
      // if (!memoTypes || isEmpty(memoTypes) || memoTypes.length < 1) {
      fetchMemoTypes()
      // }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <WrappedComponent {...props}
      recoilState={{
        memoTypes
      }} />;
  };

  return EnhancedComponent;
}

export default withRecoilState;
