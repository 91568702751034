import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
    Button,
    Card,
    Col,
    FormInstance,
    Modal,
    Row,
    Spin
} from "antd"
import { AxiosError } from "axios"
import lodash, { get } from "lodash"
import React from "react"
import CustomerApi from "../../../Api/CustomerApi"
import ProjectApi from "../../../Api/ProjectApi"
import ReceiptVoucherApi from "../../../Api/ReceiptVoucher"
import ReceiptVoucherCreateCommand from "../../../Command/ReceiptVoucherCreateCommand"
import ReceiptVoucherUpdateCommand from "../../../Command/ReceiptVoucherUpdateCommand"
import DefaultLayout from "../../../Component/Layout/Default"
import { LogModal } from "../../../Component/LogModal"
import { LogModalRef } from "../../../Component/LogModal/type"
import ModalReceiptVoucherCreate from "../../../Component/ModalReceiptVoucherCreate"
import { NotificationCommon } from "../../../Component/Notification"
import { AppContext } from "../../../Context/AppContext"
import AccountDomain from "../../../Domain/AccountDomain"
import CustomerDomain from "../../../Domain/CustomerDomain"
import ProjectDomain from "../../../Domain/ProjectDomain"
import ReceiptVoucherDomain, { ReceiptVoucherModel } from "../../../Domain/ReceiptVoucherDomain"
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface"
import { LogResrouceType } from "../../../QueryCriteria/LogQueryCriterial"
import SuggestCustomerQueryCriteria from "../../../QueryCriteria/SuggestCustomerQueryCriteria"
import { ACCOUNT_PERMISSIONS } from "../../../Util/Constants"
import SecurityService from "../../../Util/SecurityService"
import Utils from "../../../Util/Utils"
import { ReceiptVoucherEdit } from "./Edit"
import { ListItem } from "./LogListItem"
import ReceiptVoucherReadOnly from "./Readonly"
import VoucherBarcode from "./VoucherBarcode"
import VoucherQrCode from "./VoucherQrCode"
import withRecoilState from "../../../HOC/withRecoilState"

interface Props extends DefaultPropsInterface {
    history: any
    recoilState?: any
}

interface State {
    account: AccountDomain
    loading: boolean
    receiptVoucher: any
    update: boolean
    form: ReceiptVoucherUpdateCommand
    projects: Array<ProjectDomain>
    customers: Array<CustomerDomain>
    payerType: string
    project: any
    customerLoading: boolean
    isChange: boolean
    isShowModalCreate?: boolean
    deliveryNotes: any[]
    // deliveryNotesProject: any
    openEditModal: boolean
}

class ReceiptVoucherDetail extends React.Component<Props, State> {
    static contextType = AppContext
    formRef = React.createRef<FormInstance>()
    logRef = React.createRef<LogModalRef>()

    state: State = {
        account: Utils.getAppContext(this).state.account,
        loading: false,
        receiptVoucher: { ...ReceiptVoucherModel },
        update: false,
        form: { ref: "", timestamp: "", memo: "", remark: "", payerRef: "", payer: "", amount: 0, project: '' },
        projects: [],
        customers: [],
        payerType: "system",
        project: undefined,
        customerLoading: false,
        isChange: false,
        isShowModalCreate: false,
        deliveryNotes: [],
        // deliveryNotesProject: undefined,
        openEditModal: false
    }

    componentDidMount() {
        this.fetchReceiptVoucher()
        this.fetchProjects({})

        if (this.props.location.search.includes('status=PENDING')) {
            NotificationCommon.warning({ message: 'Xác nhận giao dịch không thành công!' })
        }

        this.props.history.replace({
            search: ''
        })
    }

    fetchProjects = (filter = {}) => {
        this.setState({ loading: true })
        filter = {
            ...filter,
            offset: 0,
            limit: 1000,
        }
        ProjectApi.filter(filter)
            .then((response) => {
                this.setState({
                    projects: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    getCode = () => {
        return this.props.match.params.code
    }

    fetchReceiptVoucher = () => {
        this.setState({ loading: true })
        ReceiptVoucherApi.getByCode(this.getCode())
            .then((response) => {
                const data = response.data
                const project = data.payerRef ? get(data, "payerRef.project") : data.project ? get(data, 'project') : undefined
                const username = get(data, "payerRef.username")
                this.setState({
                    receiptVoucher: data,
                    form: {
                        ref: data.ref,
                        timestamp: data.timestamp,
                        memo: data.memo,
                        remark: data.remark,
                        payerRef: get(data.payerRef, "username"),
                        payer: data.payer,
                        amount: data.amount,
                        orderCode: data.orderCode,
                        deliveryNote: data.deliveryNote,
                        type: data.type
                    },
                    payerType: (project && data.payerRef) ? "system" : "person",
                    project: data.project || undefined,
                    // deliveryNotesProject: (data.project && data.deliveryNote) ? data.project : undefined,
                    isChange: false
                })
                this.formRef.current?.setFieldsValue({ memo: data.memo, amount: data.amount })

                if (project && data.payerRef) {
                    this.fetchCustomers({ project, query: username })
                }
            })
            .catch((err) => {
                if (get(err, "response.status") === 404) {
                    NotificationCommon.error({ message: "Không tìm thấy phiếu thu!" })
                } else {
                    NotificationCommon.error({ message: `Lỗi: ${err.message}` })
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onReject = () => {
        this.setState({ loading: true })
        ReceiptVoucherApi.reject(this.getCode())
            .then(() => {
                NotificationCommon.success({ message: "Hủy phiếu thu thành công!" })
                this.fetchReceiptVoucher()
            })
            .catch(() => {
                NotificationCommon.error({ message: "Hủy phiếu thu thất bại!" })
                this.setState({ loading: false })
            })
    }

    onApprove = () => {
        this.setState({ loading: true })
        ReceiptVoucherApi.approve(this.getCode())
            .then(() => {
                NotificationCommon.success({ message: "Xác nhận giao dịch thành công!" })
                this.fetchReceiptVoucher()
            })
            .catch((error: AxiosError) => {
                let message: any = "Xác nhận giao dịch thất bại!"
                if (lodash.get(error.response, "status") === 400) {
                    if (lodash.get(error.response, "data.title") === "ref_exist") {
                        const transactionCode = lodash.get(error.response, "data.transactionCode")
                        message = (
                            <span>
                                Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b>
                            </span>
                        )
                        NotificationCommon.error({
                            message: message,
                        })
                    } else if (get(error.response, "data.title") === "source_exist") {
                        NotificationCommon.error({
                            message: "Không thể xác nhận vì đã tồn tại giao dịch",
                        })
                    } else if (lodash.get(error, "response.data.title") === "project_inactive") {
                        NotificationCommon.error({
                            message: "Hệ thống ngừng hoạt động. Vui lòng kiểm tra lại.",
                        })
                    } else if (lodash.get(error, "response.data.title") === "invalid_customer") {
                        NotificationCommon.error({
                            message: "Sai định dạng username",
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === "customer_not_exist") {
                        let details = (lodash.get(error, 'response.data.detail') as string | any)?.split(/'/);
                        NotificationCommon.error({
                            message: <span>Không tồn tại username <span className="bold">{details[1]}</span> trong hệ thống <span className={'bold'}>{details[3]}</span>.</span>,
                        })
                    } else if (lodash.get(error, "response.data.title") === "voucher_ineligible") {
                        NotificationCommon.error({
                            message: "Giao dịch đã được xác nhận. Vui lòng kiểm tra lại",
                        })
                    } else {
                        NotificationCommon.error({
                            message: message,
                        })
                    }
                } else if (lodash.get(error.response, "status") === 403) {
                    NotificationCommon.error({
                        message: "Bạn không có quyền thực hiện thao tác này",
                    })
                } else {
                    NotificationCommon.error({ message })
                }
                this.setState({ loading: false })
            })
    }

    onAcceptReview = () => {
        this.setState({ loading: true })
        ReceiptVoucherApi.review(this.getCode()).then(() => {
            NotificationCommon.success({
                message: "Duyệt phiếu thành công!",
            })
            this.fetchReceiptVoucher()
        }).catch((err) => {
            let message: string;
            let error = err.response.data;
            if (error.title === 'receipt_voucher_not_found') {
                message = 'Phiếu thu không tồn tại!'
            } else if (error.title === 'review_payment_voucher_ineligible') {
                message = 'Duyệt phiếu thu thất bại do phiếu chưa xác nhận giao dịch'
            } else if (error.title === 'project_inactive') {
                message = 'Hệ thống ngừng hoạt động. Vui lòng kiểm tra lại.'
            } else if (error.status === 403) {
                message = 'Bạn không có quyền thực hiện thao tác này'
            } else if (error.title === "receipt_voucher_reviewed") {
                message = "Giao dịch đã được duyệt. Vui lòng kiểm tra lại"
            } else if (err.response.data.title === 'review_receipt_voucher_ineligible' || error.title === 'transaction_rejected') {
                message = 'Giao dịch đã bị hủy. Vui lòng kiểm tra lại'
            } else {
                message = error.title
            }
            NotificationCommon.error({
                message
            })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    confirmReject = () => {
        Modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn chắc chắn muốn hủy phiếu?",
            okText: "Đồng ý",
            cancelText: "Hủy bỏ",
            onOk: this.onReject,
            okButtonProps: {
                icon: <i className="fa-solid fa-check pd-r-8" />,
            },
            cancelButtonProps: {
                icon: <i className="fa-solid fa-xmark pd-r-8" />,
                type: "ghost",
            },
        })
    }

    handlePrint = () => {
        window.open(`/receipt-vouchers/${this.getCode()}/print`, "_blank")
    }

    renderAction = () => {
        const receiptVoucher: ReceiptVoucherDomain = this.state.receiptVoucher
        // const { isChange, loading, form } = this.state
        const { loading } = this.state
        const disabled: boolean = get(receiptVoucher, "reviewStatus") !== "PENDING" || loading
        // const disableSubmit: boolean = form.amount === undefined || form.amount === "" || !form.memo

        return (
            <Row gutter={12} onLoad={() => <div />}>
                <Col>
                    {!loading && get(receiptVoucher, "account.type") !== "BANK" && (
                        <Col>
                            <Button type={"link"} onClick={this.handlePrint}>
                                In phiếu
                            </Button>
                        </Col>
                    )}
                </Col>

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_CREATE) && <Col>
                    <Button
                        type="link"
                        className="pd-r-6"
                        onClick={() => this.setIsShowModalCreate(true)}
                    >
                        <i className="fa-regular fa-clone mg-r-4" /> Nhân bản phiếu
                    </Button>
                </Col>}

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE) &&
                    <Col>
                        <Button
                            // disabled={!isChange || disableSubmit}
                            disabled={get(receiptVoucher, "status.code") !== 'PENDING'}
                            // onClick={this.handleSubmit}
                            onClick={() => this.setState({
                                openEditModal: true
                            })}
                            icon={<i className="fa-solid fa-pen-to-square mg-r-8" />}
                        >
                            Chỉnh sửa
                        </Button>
                    </Col>
                }

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_APPROVE) && <Col>
                    <Button
                        onClick={this.onApprove}
                        disabled={get(receiptVoucher, 'status.code') !== 'PENDING'}
                        icon={<i className="fa-solid fa-file-check mg-r-8" />}
                    >
                        Xác nhận giao dịch
                    </Button>
                </Col>}

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_REVIEW) && <Col>
                    <Button
                        onClick={this.onAcceptReview}
                        disabled={disabled || get(receiptVoucher, 'status.code') === 'REJECTED' || get(receiptVoucher, 'status.code') === 'PENDING'}
                        icon={<i className="fa-regular fa-file-check mg-r-8" />}
                    >
                        Duyệt phiếu
                    </Button>
                </Col>}

                {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_REJECT) && <Col>
                    <Button
                        danger
                        onClick={this.confirmReject}
                        disabled={get(receiptVoucher, 'status.code') === 'APPROVED' || get(receiptVoucher, 'status.code') === 'REJECTED'}
                        icon={<i className="fa-solid fa-xmark mg-r-8" />}
                    >
                        Hủy phiếu
                    </Button>
                </Col>}
            </Row>
        )
    }

    allowSubmitEdit = () => {
        const { isChange, form } = this.state
        // console.log(form.amount)
        const disableSubmit: boolean = !form.amount || form.amount === undefined || form.amount === "" || !form.memo || ((!form.orderCode) && ['DEPOSIT_BY_PAYMENT', 'ORDER'].includes(form.type as string))
        return !isChange || disableSubmit
    }

    setIsShowModalCreate = (visible: boolean) => {
        this.setState({
            isShowModalCreate: visible
        })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
            isChange: true,
        })
    }

    handleChangeInputNumber = (field: string, value: number) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
            isChange: true,
        })
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: field === 'payerRef' ? value?.trim() : value,
            },
            isChange: true,
        })
    }

    handleChangeDatePicker = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value ? value.toISOString() : null,
            },
            isChange: true,
        })
    }

    handleSubmit = () => {
        const { form } = this.state;
        // console.log(deliveryNotesProject, project)
        // if (project !== deliveryNotesProject) {
        //     NotificationCommon.error({
        //         key: 'project_not_same',
        //         message: 'Hệ thống của người nộp không đúng với hệ thống phiếu xuất!'
        //     })
        //     return
        // }
        if (SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_UPDATE))
            this.handleUpdateReceiptVoucher({
                ...form,
            })
    }

    handleUpdateReceiptVoucher = (command: ReceiptVoucherUpdateCommand) => {
        const form = { ...command }
        const { payerType, receiptVoucher, project } = this.state
        const code = get(receiptVoucher, "code")
        if (payerType === "person") {
            lodash.unset(form, "payerRef")
            lodash.unset(form, 'project')
        } else {
            lodash.unset(form, "payer")
            if (project && form.payerRef) {
                form.payerRef = `c:${project}:${form.payerRef}`
                form.project = project
            } else {
                form.project = project;
            }
        }
        form.project = project

        // if (!form.project) {
        //     form.project = deliveryNotesProject
        // }

        this.setState({ loading: true })
        ReceiptVoucherApi.update(code!, form)
            .then(() => {
                NotificationCommon.success({ message: "Cập nhật phiếu thành công" })
                this.fetchReceiptVoucher()
                this.setState({ loading: false, openEditModal: false })
            })
            .catch((error: AxiosError) => {
                let message: any = "Có lỗi. Vui lòng kiểm tra lại thông tin trên form"
                if (lodash.get(error.response, "status") === 400) {
                    if (lodash.get(error.response, "data.title") === "ref_exist") {
                        const transactionCode = lodash.get(error.response, "data.transactionCode")
                        message = (
                            <span>
                                Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b>
                            </span>
                        )
                        NotificationCommon.error({ message })
                    } else if (lodash.get(error, "response.data.title") === "ref_exist_pending_voucher") {
                        return this.confirmForceUpdateVoucher(form, error)
                    } else if (lodash.get(error.response, "data.title") === "receipt_ineligible") {
                        message = "Không thể cập nhật thông tin phiếu trước thời gian chốt sổ gần nhất"
                        NotificationCommon.error({ message })
                    } else if (lodash.get(error.response, 'data.title') === 'project_not_found') {
                        message = "Không được bỏ trống hệ thống với loại giao dịch nạp tiền bằng mã thanh toán"
                    }
                    else {
                        NotificationCommon.error({
                            message: "Lỗi: " + error.message,
                        })
                    }
                } else {
                    NotificationCommon.error({ message })
                }
                this.setState({ loading: false, openEditModal: false })
            })
    }

    confirmForceUpdateVoucher = (form: ReceiptVoucherUpdateCommand, error: AxiosError) => {
        const voucherCodes: any = lodash.get(error.response, "data.voucherCodes")
        const renderVoucherCodes = voucherCodes?.map((_code: any, _index: any) => (
            <>
                <b className={_index < lodash.size(voucherCodes) - 1 ? "has-comma" : ""}>{_code}</b>
            </>
        ))
        const message = (
            <span>Mã bút toán đã nằm trong phiếu thu {renderVoucherCodes}. Bạn có muốn tiếp tục cập nhật không?</span>
        )
        Modal.confirm({
            title: message,
            okText: "Xác nhận",
            cancelText: "Hủy bỏ",
            onOk: () => {
                this.handleUpdateReceiptVoucher({
                    ...form,
                    force: true,
                })
            },
            centered: true,
            okButtonProps: {
                icon: <i className="fa-solid fa-check pd-r-8" />,
            },
            cancelButtonProps: {
                icon: <i className="fa-solid fa-xmark pd-r-8" />,
                type: "ghost",
            },
        })
    }

    fetchCustomers = (filter: SuggestCustomerQueryCriteria = {}) => {
        this.setState({ customerLoading: true })
        CustomerApi.getSuggestCustomers({ limit: "300", ...filter })
            .then((response) => {
                this.setState({
                    customers: response.data,
                })
            })
            .finally(() => {
                this.setState({ customerLoading: false })
            })
    }

    handleChangeProject = (value: any) => {
        this.setState({
            project: value || undefined,
            form: { ...this.state.form, project: value, payerRef: undefined },
            isChange: true,
            customers: [],
        })
    }

    handleSearchStaff = (value: any) => {
        if (this.state.project) {
            if (value && value.toString().length >= 3) {
                this.fetchCustomers({
                    query: value,
                    project: this.state.project,
                })
            } else if (!value) {
                this.fetchCustomers({
                    project: this.state.project,
                    query: "---",
                })
            }
        }
    }

    handleChangePayerType = (e: any) => {
        if (!this.state.form.deliveryNote) {
            this.setState({
                payerType: e.target.value,
                isChange: true,
                form: { ...this.state.form, payer: '', payerRef: '', project: undefined },
                project: undefined
            })
        }
        else {
            this.setState({
                payerType: e.target.value,
                isChange: true,
                form: { ...this.state.form, payer: '', payerRef: '' },
            })
        }

    }

    openLogModal = () => {
        const { account, receiptVoucher } = this.state

        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.RECEIPT_VOUCHER,
            resourceCode: receiptVoucher.code,
        })
    }

    handleCreateReceiptVoucher = (form: ReceiptVoucherCreateCommand) => {
        const { account } = this.state;
        this.setState({ loading: true });
        ReceiptVoucherApi.create(form)
            .then((response) => {
                NotificationCommon.success({
                    message: "Tạo phiếu thu thành công"
                });
                this.setState({ isShowModalCreate: false });
                Utils.getAppContext(this).func.fetchAccountAndUpdateState(account.code);
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error, 'response.status') === 400) {
                    if (lodash.get(error, 'response.data.title') === 'receipt_ineligible') {
                        NotificationCommon.error({
                            message: 'Thời gian giao dịch không hợp lệ do đã chốt sổ'
                        })
                    }
                    else if (lodash.get(error, 'response.data.title') === 'ref_exist') {
                        NotificationCommon.error({
                            message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(error.response, 'data.transactionCode')}`
                        })
                    }
                    else if (lodash.get(error, 'response.data.title') === 'ref_exist_pending_voucher') {
                        this.confirmForceCreateVoucher(form, error);
                    }
                    else if (lodash.get(error, "response.data.title") === "invalid_customer") {
                        NotificationCommon.error({
                            message: "Sai định dạng username",
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === "customer_not_exist") {
                        let details = (lodash.get(error, 'response.data.detail') as any)?.split(/'/);
                        NotificationCommon.error({
                            message: <span>Không tồn tại username <span className="bold">{details[1]}</span> trong hệ thống <span className={'bold'}>{details[3]}</span>.</span>,
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === "transaction_type_ineligible") {
                        NotificationCommon.error({
                            message: "Lỗi mã thanh toán là loại nạp tiền nhưng giao dịch lại không phải là loại giao dịch nạp tiền cho khách bằng mã thanh toán",
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === "transaction_not_found") {
                        NotificationCommon.error({
                            message: "Không matching được giao dịch cho đơn hàng",
                        })
                    }
                    else if (lodash.get(error, "response.data.title") === 'order_code_existed') {
                        let data = lodash.get(error.response, 'data.detail')
                        console.log(data)
                        NotificationCommon.error({
                            message: `Mã thanh toán đã được sử dụng trong phiếu thu đã phát sinh giao dịch. Vui lòng kiểm tra lại`,
                        })
                    }
                    else {
                        NotificationCommon.error({
                            message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form"
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    confirmForceCreateVoucher = (form: ReceiptVoucherCreateCommand, error: AxiosError) => {
        const voucherCodes = lodash.get(error.response, 'data.voucherCodes');
        Modal.confirm({
            title: `Mã bút toán đã nằm trong phiếu thu ${(voucherCodes as any)?.join(',')}. Bạn có muốn tiếp tục tạo phiếu không?`,
            okText: "Xác nhận",
            cancelText: "Bỏ qua",
            onOk: () => {
                this.handleCreateReceiptVoucher({
                    ...form,
                    force: true
                })
            }
        })
    }

    fetchDeliveryNote = async (code: string) => {
        const searchParamsTemp = {
            code,
            project: this.state.project
        }
        try {
            const response = await ReceiptVoucherApi.getDeliveryNote(searchParamsTemp)
            this.setState({ deliveryNotes: response.data })
        } catch (error) {
            //
        }
    }

    handleSearchDeliveryNote = (value: any) => {
        if (value && value.toString().length >= 3) {
            this.fetchDeliveryNote(value)
        }
    }

    render() {
        const { recoilState } = this.props
        const receiptVoucher: any = this.state.receiptVoucher
        const { form, projects, customers, payerType, project, loading, account, isShowModalCreate } = this.state
        const accountProjects = account.projects || []
        return (
            <DefaultLayout
                {...this.props}
                title={"Chi tiết phiếu thu"}
                breadcrumb={[
                    { title: "Danh Sách Phiếu Thu", href: "/receipt-vouchers" },
                    { title: `Phiếu thu ${receiptVoucher.code}` },
                ]}
            >
                <Spin spinning={loading}>
                    <div className="main-content payment-details mx-12 my-12">
                        <Row gutter={12}>
                            <Col xs={24} lg={18}>
                                <Card
                                    title={
                                        <span className="capitalize">
                                            <Button
                                                type="text"
                                                onClick={() => this.props.history.goBack()}
                                                className="pd-l-0 pd-r-4"
                                            >
                                                <i className="fa-solid fa-angle-left" />
                                            </Button>
                                            Thông tin phiếu thu

                                            {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_LOG_VIEW) && <Button
                                                type="link"
                                                className="text-blue pd-l-7 underline  mg-l-4"
                                                onClick={this.openLogModal.bind(this)}
                                            >
                                                <i className="fa-regular fa-file-lines dpl-il mg-r-4" />Log
                                            </Button>
                                            }

                                        </span>
                                    }
                                    className={"mg-bt-20"}
                                    extra={!loading ? this.renderAction() : null}
                                >
                                    {/* {get(receiptVoucher, "status.code") !== 'PENDING' ? ( */}
                                    <ReceiptVoucherReadOnly
                                        memoTypes={recoilState.memoTypes}
                                        receiptVoucher={receiptVoucher}
                                    />
                                    {/* ) : ( */}

                                    {/* )} */}
                                </Card>
                            </Col>
                            <Col xs={24} lg={6}>
                                <VoucherQrCode receiptVoucher={receiptVoucher} />
                                <VoucherBarcode receiptVoucher={receiptVoucher} />
                                {/*<ActionLog /> */}
                            </Col>
                        </Row>
                    </div>
                </Spin>

                <LogModal
                    ref={this.logRef}
                    title="Log phiếu thu"
                    ListItemContent={(props) => <ListItem {...props} account={account} />}
                />

                {isShowModalCreate && (
                    <ModalReceiptVoucherCreate
                        loading={loading}
                        voucherCloneCode={receiptVoucher.code}
                        onVisibleChange={this.setIsShowModalCreate}
                        onSubmit={this.handleCreateReceiptVoucher}
                        memoTypes={recoilState.memoTypes}
                    />
                )}

                {
                    this.state.openEditModal && <ReceiptVoucherEdit
                        disableSubmit={this.allowSubmitEdit}
                        onCancel={() => {
                            this.setState({
                                openEditModal: false,
                            })
                            this.fetchReceiptVoucher()
                        }}
                        onOk={this.handleSubmit}
                        openModal={this.state.openEditModal}
                        receiptVoucher={receiptVoucher}
                        form={{
                            ...receiptVoucher,
                            ...form
                        }}
                        accountProjects={accountProjects}
                        loading={loading}
                        deliveryNotes={this.state.deliveryNotes}
                        handleChangeInput={this.handleChangeInput}
                        handleSubmit={this.handleSubmit}
                        handleChangeProject={this.handleChangeProject}
                        handleChangeSelect={this.handleChangeSelect}
                        projects={projects}
                        handleSearchDeliveryNote={this.handleSearchDeliveryNote}
                        handleChangePayerType={this.handleChangePayerType}
                        payerType={payerType} project={project}
                        handleSearchStaff={this.handleSearchStaff}
                        customers={customers}
                        handleChangeInputNumber={this.handleChangeInputNumber}
                        handleChangeDatePicker={this.handleChangeDatePicker}
                        isEditing={loading}
                        memoTypes={recoilState.memoTypes}
                    />
                }
            </DefaultLayout>
        )
    }
}

export default withRecoilState(ReceiptVoucherDetail)
